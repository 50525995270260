@font-face {
  font-family: 'Tenango';
  src: url('./assets/fonts/tenango/PhotographSignature.ttf') format('truetype'); /* Asegúrate de usar el formato correcto */
  font-weight: normal; /* Define estilos (normal, bold, etc.) */
  font-style: normal;  /* Define el estilo (normal, italic, etc.) */
}


.App{
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spiral{
width: 99vw;
height: 60vh;
margin: 1em;
padding: .2em;
overflow-y: hidden;
}

.container{
  height: 300px;
  width: 300px;
  position: absolute;
  top: 30%;
  left: 50%;
  margin: -150px 0 0 -150px;

}

.square{
  height: 94%;
  width: 94%;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -47% 0 0 -47%;
}

.black{ 
  background: black;  
  animation: rotate 10s infinite linear; 
  transform-origin: center;
  will-change: transform;
  
}

@keyframes rotate{
  0%{ transform: rotate(0deg); }
    100%{ transform: rotate(360deg); }	
}


.msj{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.msj img{
  width: 60px;
  margin: 0;
  padding: .2em;
}

.msj p{
  font-family: 'Tenango';
  font-size: 3em;
  margin: .3em;
}

.patita{
  display: flex;
  justify-content: center;
}

.patita p{
  font-size: 1em;
  font-weight: normal;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}